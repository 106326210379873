<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <edit-data-device-location v-if="realDevice" ref="editDeviceLocation" :devices="[realDevice]"></edit-data-device-location>
    <div class="columns">

      <div class="column m-0 p-0 ml-3 mt-1" style="width: 32px; max-width: 32px">
        <view-guard :permission="'site-plan:update-devices'">
          <b-checkbox @input="onSelectionChanged" v-model="isSelected" :disabled="selectedSites.length > 0"></b-checkbox>
        </view-guard>
      </div>

      <div class="column m-0 p-0 ml-2">
        <div class="columns m-0 p-0">
          <div class="column m-0 p-0 is-1">
            <div class="is-size-7 has-text-grey-light">Type</div>
            <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.type }}</div>
          </div>
          <div class="column m-0 p-0 pl-1 is-1">
            <div class="is-size-7 has-text-grey-light">Device name</div>
            <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.name }}</div>
          </div>
          <div class="column m-0 p-0 pl-1 is-3">
            <div class="is-size-7 has-text-grey-light">Location</div>
            <div class="is-size-6 has-text-weight-semibold has-text-black">{{ device.location.coordinates }}</div>
          </div>
          <div class="column m-0 p-0 pl-1 is-1">
            <div class="is-size-7 has-text-grey-light">Ext Device ID</div>
            <div v-if="device.extDeviceId" class="is-size-6 has-text-weight-semibold has-text-black">
              {{ device.extDeviceId }}
            </div>
            <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
          </div>
          <div class="column m-0 p-0 is-6">
            <div class="is-size-7 has-text-grey-light">Configuration</div>
            <div class="is-flex">
              <div v-if="device.settingsProfile">
                <b-tag style="background: #eaeaea" v-for="item in getParsedSettingsProfile()" :key="item"
                       class="mr-1 mb-1">
                  {{ item }}
                </b-tag>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
        </div>
        <div class="columns m-0 p-0 mt-2">
          <div class="column m-0 p-0 pr-3 is-6">
            <div class="is-size-7 has-text-grey-light">Installation Instruction</div>
            <div v-if="installationInstruction" class="is-size-6 has-text-weight-semibold has-text-black">
              {{ installationInstruction }}
            </div>
            <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
          </div>
          <div class="column m-0 p-0 pr-3 is-6">
            <div class="is-size-7 has-text-grey-light">Decommission Instruction</div>
            <div v-if="decommissionInstruction" class="is-size-6 has-text-weight-semibold has-text-black">
              {{ decommissionInstruction }}
            </div>
            <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
          </div>
        </div>
        <div class="columns m-0 p-0">
          <div class="column m-0 p-0 pr-2">
            <div class="is-size-7 has-text-grey-light mt-1">Installation TODO List</div>
            <div class="is-flex">
              <div v-if="installationTodoList">
                <b-tooltip :label="item.doneAt ? getDateAndUser(item) : 'Not Done'" :delay="1000" type="is-dark"
                           v-for="item in installationTodoList" :key="item.name">
                  <b-taglist attached class="m-0 mr-1 mb-1" style="display: inline-flex; cursor: pointer">
                    <b-tag :type="item.doneAt ? 'is-success' : 'is-danger'" class="m-0">
                      <b-icon :icon="item.doneAt ? 'check' : 'close'" size="is-small"></b-icon>
                    </b-tag>
                    <b-tag class="m-0" style="background: #eaeaea">{{ item.name }}</b-tag>
                  </b-taglist>
                </b-tooltip>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
          <div class="column m-0 p-0 pr-2 pl-0">
            <div class="is-size-7 has-text-grey-light mt-1">Decommission TODO List</div>
            <div class="is-flex">
              <div v-if="decommissionTodoList">
                <b-tooltip :label="item.doneAt ? getDateAndUser(item) : 'Not Done'" :delay="1000" type="is-dark"
                           v-for="item in decommissionTodoList" :key="item.name">
                  <b-taglist attached class="m-0 mr-1 mb-1" style="display: inline-flex; cursor: pointer">
                    <b-tag :type="item.doneAt ? 'is-success' : 'is-danger'" class="m-0">
                      <b-icon :icon="item.doneAt ? 'check' : 'close'" size="is-small"></b-icon>
                    </b-tag>
                    <b-tag class="m-0" style="background: #eaeaea">{{ item.name }}</b-tag>
                  </b-taglist>
                </b-tooltip>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
          <div class="column m-0 p-0" style="max-width: 0px">
            <view-guard :permission="'site-plan:update-devices'">
              <b-tooltip label="Maintenance" :delay="1000" type="is-dark">
                <div style="width: 42px; height: 42px; position: relative">
                  <a @click="onClickMaintenance" style="position: absolute; top: 8px; left: 4px">
                    <b-icon icon="tools">
                    </b-icon>
                  </a>
                  <div v-if="getMaintenanceTaskNum()" class="numberCircle" style="position: absolute;top: 0;right: 2px;"
                       :style="{background: getMaintenanceTasksColor()}">{{ getMaintenanceTaskNum() }}
                  </div>
                </div>
              </b-tooltip>
            </view-guard>
          </div>
        </div>
        <div class="columns m-0 p-0">
          <div class="column m-0 p-0 pr-2">
            <div class="is-size-7 has-text-grey-light mt-1">Collection period</div>
            <div class="is-flex">
              <div v-if="collectionPeriod" class="is-flex">
                <b-icon icon="calendar" size="is-small" class="mt-1 mr-1"></b-icon>
                <div>{{ collectionPeriod }}</div>
                <div class="is-flex is-flex-direction-column ml-4">
                  <div v-for="timeRange in collectionTimes" :key="timeRange">
                    <div class="is-flex">
                      <b-icon icon="clock-outline" size="is-small" class="ml-2 mt-1 mr-1"></b-icon>
                      <div>{{ timeRange }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
          <div class="column m-0 p-0 pr-2 pl-0" v-if="havePeriods">
            <div class="is-size-7 has-text-grey-light mt-1">Processing periods</div>
            <div class="is-flex" v-for="(period, index) in this.device.processingPeriods" :key="index">
              <div v-if="processingPeriodByIndex(index)" class="is-flex">
                <b-icon icon="calendar" size="is-small" class="mt-1 mr-1"></b-icon>
                <div>{{ processingPeriodByIndex(index) }}</div>
                <div class="is-flex is-flex-direction-column ml-4">
                  <div v-for="timeRange in processingTimesByIndex(index)" :key="timeRange">
                    <div class="is-flex">
                      <b-icon icon="clock-outline" size="is-small" class="ml-2 mt-1 mr-1"></b-icon>
                      <div>{{ timeRange }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
          <div class="column m-0 p-0 pr-2 pl-0" v-else>
            <div class="is-size-7 has-text-grey-light mt-1">Processing period</div>
            <div class="is-flex">
              <div v-if="processingPeriod" class="is-flex">
                <b-icon icon="calendar" size="is-small" class="mt-1 mr-1"></b-icon>
                <div>{{ processingPeriod }}</div>
                <div class="is-flex is-flex-direction-column ml-4">
                  <div v-for="timeRange in processingTimes" :key="timeRange">
                    <div class="is-flex">
                      <b-icon icon="clock-outline" size="is-small" class="ml-2 mt-1 mr-1"></b-icon>
                      <div>{{ timeRange }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="is-size-6 has-text-weight-normal has-text-warning">Undefined</div>
            </div>
          </div>
        </div>
      </div>

      <div class="column m-0 p-0 mr-1 ml-auto" style="max-width: 32px">
        <div class="is-flex is-flex-direction-column mt-3 ml-auto">
          <view-guard :permission="'site-plan:update-devices'">
            <b-tooltip label="Edit device" :delay="1000" type="is-dark">
              <a @click="onClickEdit">
                <b-icon icon="pencil-circle-outline">
                </b-icon>
              </a>
            </b-tooltip>
          </view-guard>
          <view-guard :permission="'site-plan:delete-plan-devices'">
            <b-tooltip v-if="site.status === 'PLANNING'" label="Delete device" :delay="1000" type="is-dark">
              <a @click="confirmDelete">
                <b-icon icon="delete-circle-outline">
                </b-icon>
              </a>
            </b-tooltip>
          </view-guard>
          <view-guard :permission="'report-provisioning:history'">
            <b-tooltip label="Device History" :delay="1000" type="is-dark">
              <a @click="onClickHistory">
                <b-icon icon="clipboard-text-clock">
                </b-icon>
              </a>
            </b-tooltip>
          </view-guard>
        </div>
      </div>
    </div>
    <div class="m-0 ml-4 mt-5">
      <div>Device data</div>
      <hr class="m-0 p-0 mb-2">
      <div class="columns m-0 p-0" v-if="realDevice">
        <div class="column m-0 p-0">
          <div class="is-size-7 has-text-grey-light">Name</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ realDevice.name }}</div>
        </div>
        <div class="column m-0 p-0">
          <div class="is-size-7 has-text-grey-light">Type</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ realDevice.type }}</div>
        </div>
        <div class="column m-0 p-0">
          <div class="is-size-7 has-text-grey-light">Ext Device ID</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">{{ realDevice.extDeviceId }}</div>
        </div>
        <div class="column m-0 p-0">
          <div class="is-size-7 has-text-grey-light">Status</div>
          <div class="is-size-6 has-text-weight-semibold has-text-black">
            <b-tag class="mr-1" type="is-primary" :style="{'background': getDeviceStatus(realDevice).color}">
              {{ getDeviceStatus(realDevice).name }}
            </b-tag>
          </div>
        </div>
        <view-guard :permission="'data-device:change-status'">
          <b-tooltip label="Change status to READY" :delay="1000" type="is-dark">
            <div class="column m-0 p-0 is-flex is-flex-direction-column" style="max-width: 96px"
                 v-if="realDevice.currentStatus && realDevice.currentStatus === 'CREATED'">
              <b-button type="is-primary" size="is-small" class="mt-4 mr-2" @click="onDeviceReady">READY</b-button>
            </div>
          </b-tooltip>
        </view-guard>
        <view-guard :permission="'data-device:update'">
          <b-tooltip label="Edit Data Device Location" :delay="1000" type="is-dark">
            <div class="column m-0 p-0 is-flex is-flex-direction-column" style="max-width: 96px">
              <b-button size="is-small" class="mt-4" @click="onEditDeviceLocation">Edit Location</b-button>
            </div>
          </b-tooltip>
        </view-guard>
      </div>
      <div v-else class="is-flex is-align-items-center dashed-border pt-2 pb-0">
        <h4 class="is-size-5 ml-auto mr-auto has-text-grey-light has-text-weight-light">No Installed Device</h4>
      </div>
    </div>
    <hr style="background-color: rgba(0,0,0,0.2)">
  </div>
</template>

<script>
import UserAvatar from "@/components/avatar/avatar.vue";
import {mapActions, mapGetters} from "vuex";
import {
  DeviceStatus,
  getDateObjectFromMelbourneUtcToLocal,
  getDateRange,
  getDateTime, parseTimeRanges,
  TaskType
} from "@/utils/utils";
import ViewGuard from "@/components/view-guard/view-guard.vue";
import EditDataDeviceLocation
  from "@/modules/projects-module/components/edit-data-device-location/edit-data-device-location.vue";

export default {
  name: "site-device-view",
  components: {EditDataDeviceLocation, ViewGuard},
  props: {
    device: {
      type: Object,
      required: true
    },
    realDevice: {
      type: Object
    },
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSelected: false,
      isLoading: false,
    }
  },
  methods: {
    ...mapActions({
      selectDevice: 'projectsModule/selectDevice',
      deselectDevice: 'projectsModule/deselectDevice',
      deleteSitePlanDevice: 'projectsModule/deleteSitePlanDevice',
      changeDataDeviceStatus: 'projectsModule/changeDataDeviceStatus',
    }),
    onDeviceReady() {
      this.confirmReady()
    },
    onClickEdit() {
      console.log('On edit click')
      this.$emit('onClickEditDevice', this.device)
    },
    onClickMaintenance() {
      console.log('On add-edit-maintenance click')
      this.$emit('onClickMaintenance', this.device)
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to delete Site Plan Device?',
        onConfirm: () => this.delete()
      })
    },
    confirmReady() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure the device is installed and ready for production?',
        confirmText: `Yes`,
        onConfirm: () => this.changeDeviceStatusToReady()
      })
    },
    async changeDeviceStatusToReady() {
      console.log('On change to ready', this.realDevice)
      this.isLoading = true
      let payload = {
        id: this.realDevice._id,
        status: DeviceStatus.READY.name
      }
      let response = await this.changeDataDeviceStatus(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Device status successfully changed', 'is-success')
      }
      this.isLoading = false
    },
    async delete() {
      console.log('Call delete site plan device', this.device._id)

      this.isLoading = true
      let response = await this.deleteSitePlanDevice(this.device._id)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Site Plan Device successfully deleted', 'is-success')
      }
      this.isLoading = false

    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    onClickHistory() {
      console.log('On history click')
      this.$emit('onClickHistory', this.device)
    },
    onSelectionChanged(value) {
      console.log('On selection changed: ', value)
      let dev = this.device
      dev.siteName = this.site.siteName
      dev.color = this.planStatusColor(this.site.status)
      if (value) {
        this.selectDevice(dev)
      } else {
        this.deselectDevice(dev)
      }

      console.log('Selected devices: ', this.selectedDevices)
    },
    getParsedSettingsProfile() {
      let parsed = []
      let entries = Object.entries(this.device.settingsProfile)
      entries.forEach(entry => {
        parsed.push(`${entry[0]}:${entry[1]}`)
      })
      return parsed
    },
    planStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    getDeviceStatus(device) {
      if (this.$store.getters.getSettings) {
        return this.$store.getters.getSettings.deviceStatuses.find(status => status.name === device.currentStatus)
      }

      return null
    },
    getDate(item) {
      return getDateTime(new Date(item.doneAt))
    },
    getMaintenanceTaskNum() {
      if (this.device && this.device.tasks && this.device.tasks.length > 0) {
        let tasks = this.device.tasks.filter(tsk => tsk.taskType === TaskType.MAINTENANCE)
        return tasks.length
      }

      return 0
    },
    getMaintenanceTasksColor() {
      let undone = false
      if (this.device && this.device.tasks && this.device.tasks.length > 0) {
        let tasks = this.device.tasks.filter(tsk => tsk.taskType === TaskType.MAINTENANCE)
        tasks.forEach(task => {
          if (task.status === 'UNDONE') {
            undone = true
          }
        })
      }
      return undone ? 'red' : '#18AA7E'
    },
    getDateAndUser(item) {
      const user = this.$store.getters.getUserById(item.doneBy)
      const userName = user && user.fullName ? user.fullName : 'Unknown'
      const dateTime = getDateTime(new Date(item.doneAt))
      return dateTime + ' by ' + userName
    },
    processingPeriodByIndex(index) {
      if (this.device && this.device.processingPeriods &&  this.device.processingPeriods.length > 0) {
        if(this.device.processingPeriods[index].dateRange && this.device.processingPeriods[index].dateRange.length > 1){
          return getDateRange(this.device.processingPeriods[index].dateRange)
        }
      }
      return null
    },
    processingTimesByIndex(index) {
      if (this.device && this.device.processingPeriods && this.device.processingPeriods.length > 0) {
        if(this.device.processingPeriods[index].dateRange && this.device.processingPeriods[index].dateRange.length > 1){
          return parseTimeRanges(this.device.processingPeriods[index].timeRanges)
        }
      }
      return null
    },
    onEditDeviceLocation(){
      if(this.$refs.editDeviceLocation){
        this.$refs.editDeviceLocation.show(true, true)
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedDevices: 'projectsModule/getSelectedDevices',
      selectedSites: 'projectsModule/getSelectedSites',
    }),
    tooltip() {
      //TODO fix tooltip
      return ''
      //return `${this.device.currentStatus.name} at ${new Date(this.device.currentStatus.date).toLocaleDateString()}`
    },
    collectionPeriod() {
      if (this.device && this.device.collectionPeriod && this.device.collectionPeriod.dateRange && this.device.collectionPeriod.dateRange.length > 1) {
        return getDateRange(this.device.collectionPeriod.dateRange)
      }

      return null
    },
    collectionTimes() {
      if (this.device && this.device.collectionPeriod && this.device.collectionPeriod.dateRange && this.device.collectionPeriod.dateRange.length > 1) {
        return parseTimeRanges(this.device.collectionPeriod.timeRanges)
      }

      return null

    },
    processingPeriod() {
      if (this.device && this.device.processingPeriod && this.device.processingPeriod.dateRange && this.device.processingPeriod.dateRange.length > 1) {
        return getDateRange(this.device.processingPeriod.dateRange)
      }

      return null
    },
    processingTimes() {
      if (this.device && this.device.processingPeriod && this.device.processingPeriod.dateRange && this.device.processingPeriod.dateRange.length > 1) {
        return parseTimeRanges(this.device.processingPeriod.timeRanges)
      }

      return null
    },
    havePeriods(){
      if (this.device && this.device.processingPeriods && this.device.processingPeriods.length > 0){
        return true
      }
      return false
    },
    installationInstruction() {
      if (this.device.tasks && this.device.tasks.length > 0) {
        let task = this.device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
        if (task) {
          return task.instruction
        }
      }

      return null
    },
    decommissionInstruction() {
      if (this.device.tasks && this.device.tasks.length > 0) {
        let task = this.device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)
        if (task) {
          return task.instruction
        }
      }

      return null
    },
    installationTodoList() {
      if (this.device.tasks && this.device.tasks.length > 0) {
        let task = this.device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
        if (task) {
          return task.todos
        }
      }

      return null
    },
    decommissionTodoList() {
      if (this.device.tasks && this.device.tasks.length > 0) {
        let task = this.device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)
        if (task) {
          return task.todos
        }
      }

      return null
    }
  },
  watch: {
    selectedDevices() {
      this.isSelected = this.selectedDevices.findIndex(device => device._id === this.device._id) !== -1
    }
  }
}
</script>

<style scoped>

.status-indicator {
  border-radius: 4px;
  background: black;
  width: 4px;
  height: 100%;
}

.numberCircle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #ff9100;
  color: white;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}

</style>