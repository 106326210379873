<template>
  <div>
    <nav class="search-box-background">
      <div class="p-2 pl-5 is-flex" style="cursor: pointer; background: none;">
        <div class="columns m-0 p-0 ml-auto mr-6" style="width: 55%">
          <div class="column p-0 pl-2 pr-2">
            <b-field>
              <b-input ref="addressInput" size="is-small" v-model="address" @input="onAddressChanged" placeholder="Address"
                       rounded
                       icon="magnify"
                       icon-right="close"
                       icon-right-clickable
                       @icon-right-click="clearAddressClick"

              ></b-input>
            </b-field>
          </div>
          <div class="column p-0 pl-2 pr-2">
            <b-field>
              <b-input ref="locationInput" v-model="location" size="is-small" @input="onCoordinatesChanged" placeholder="Longitude,Latitude" rounded
                       icon="magnify"
                       icon-right="close"
                       icon-right-clickable
                       @icon-right-click="clearLocationClick"
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="results.length > 0" class="address-results"
         :style="{'top': offsetTop + 'px', 'left':offsetLeft + 'px', 'width': width + 'px'}">
      <div v-for="(result, index) in results" :key="index">
        <div @click="onResultClicked(result)" class="p-1 pl-3 pr-3 result">{{ result.address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "map-search-box",
  data() {
    return {
      address: null,
      location: null,
      offsetLeft: 0,
      offsetTop: 0,
      width: 300,
      results: [],
      timeout: null,
    }
  },
  mounted() {

    console.log('Address input', this.$refs.addressInput)
    this.offsetLeft = this.$refs.addressInput.$el.offsetLeft + 8
    this.offsetTop = this.$refs.addressInput.$el.offsetTop + 32
    this.width = this.$refs.addressInput.$el.clientWidth - 12

    /*    setTimeout(() => {
          this.results = ['Result 1', 'Result 2', 'Result 3']
        }, 2000)*/
  },
  methods: {
    ...mapActions({
      getLocationByName: 'getLocationByName'
    }),
    async onAddressChanged(value) {
      console.log('On address changed: ', value)
      if (this.timeout) clearTimeout(this.timeout)
      if (value) {
        this.timeout = setTimeout(async () => {
          if (value && value.length > 0) {
            console.log('Call service')
            let results = await this.getLocationByName(value)
            console.log('Geo location results:', results)
            this.results = results.map(result => {
              return {
                address: result.formatted_address,
                location: result.geometry.location,
                bounds: result.geometry.bounds
              }
            })
          }
        }, 1000)
      }

    },
    onCoordinatesChanged(value) {
      // 144.99494665458116,-37.80325220243176
      console.log('On address changed: ', value)
      if (value) {
        let split = value.split(",")
        if (split.length === 2) {
          console.log(split)
          let result = {
            location: {lat: Number(split[1].trim()), lng: Number(split[0].trim())}
          }
          this.$emit('onZoomToLocation', result)
          this.address = null
          this.results = []
        }
      }

    },
    onResultClicked(result) {
      console.log('On result clicked: ', result)
      this.$emit('onZoomToLocation', result)
      this.results = []
      this.address = result.address ? result.address : this.address
      this.location = null
    },
    clearAddressClick() {
      this.address = null
      this.results = []
      this.$emit('onZoomToLocation', {})
    },
    clearLocationClick() {
      this.location = null
      this.$emit('onZoomToLocation', {})
    }
  }
}
</script>

<style lang="scss" scoped>

.address-results {
  font: 12px/20px "Barlow", Arial, Helvetica, sans-serif;
  background: white;
  width: 128px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  border-radius: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.result {
  cursor: pointer;
}

.result:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
</style>